import { lazy, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

// Types
import { IRoute, IRoutes } from 'types'
interface IGetChildElement {
  path: string
  element: ReactNode
}

// Layouts
const MainLayout = lazy(() => import('layouts/MainLayout'))

// Auth Pages
const Login = lazy(() => import('pages/auth/Login'))
const ForgotPassword = lazy(() => import('pages/auth/ForgotPassword'))

// Dashboard Page
const Dashboard = lazy(() => import('pages/dashboard/Dashboard'))
const BIDashboard = lazy(() => import('pages/bidashboard/BIDashboard'))
const OrdersAndAccountingsDashboard = lazy(() => import('pages/dashboard/OrdersAndAccountingsDashboard'))

// BI Pages
const ProductsSoldReport = lazy(() => import('pages/bidashboard/productsSoldReport/ProductsSoldReport'))

// Products Pages
const Products = lazy(() => import('pages/products/Products'))
const ProductsAdd = lazy(() => import('pages/products/add/ProductsAdd'))

// Categories Pages
const Categories = lazy(() => import('pages/categories/Categories'))
const CategoryAdd = lazy(() => import('pages/categories/add/CategoryAdd'))

// Materials Pages
const Materials = lazy(() => import('pages/materials/Materials'))
const MaterialAdd = lazy(() => import('pages/materials/add/MaterialAdd'))

// Colors Pages
const Colors = lazy(() => import('pages/colors/Colors'))
const ColorAdd = lazy(() => import('pages/colors/add/ColorAdd'))

// Company News Pages
const CompanyNews = lazy(() => import('pages/companyNews/CompanyNews'))
const CompanyNewsAdd = lazy(() => import('pages/companyNews/add/CompanyNewsAdd'))

// Self-ADS Pages
const SelfADS = lazy(() => import('pages/selfADS/SelfADS'))
const SelfADSAdd = lazy(() => import('pages/selfADS/add/SelfADSAdd'))

// Telegram Bot Subscribers Pages
const TelegramBotUsers = lazy(() => import('pages/telegramBotUsers/TelegramBotUsers'))
const SendMessageToTelegramBotUsers = lazy(() => import('pages/telegramBotUsers/SendMessageToTelegramBotUsers'))

// Subscribers Pages
const Users = lazy(() => import('pages/users/Users'))
const UsersAdd = lazy(() => import('pages/users/add/UserAdd'))

// Subscriber's Warehouse Pages
const UsersWarehouseProducts = lazy(() => import('pages/usersWarehouse/UsersWarehouseProducts'))
const UsersWarehouseProductAdd = lazy(() => import('pages/usersWarehouse/add/UsersWarehouseProductAdd'))

// Subscriber's Warehouse Pages
const UsersAccounting = lazy(() => import('pages/usersAccounting/UsersAccounting'))

// Admins Pages
const Admins = lazy(() => import('pages/admins/Admins'))
const AdminAdd = lazy(() => import('pages/admins/add/AdminAdd'))

// Admins Pages
const AdminRoles = lazy(() => import('pages/adminRoles/AdminRoles'))
const AdminRolesAdd = lazy(() => import('pages/adminRoles/add/AdminRolesAdd'))

// Admin Profile pages
const Profile = lazy(() => import('pages/user/Profile'))
const ProfileSettings = lazy(() => import('pages/user/ProfileSettings'))

// Ordering and order Accounting pages
const Orders = lazy(() => import('pages/orders/Orders'))
const OrderAdd = lazy(() => import('pages/orders/add/OrderAdd'))
const OrdersForWarehouserCabinet = lazy(() => import('pages/orders/OrdersForWarehouserCabinet'))
const OrdersForDeliverymanCabinet = lazy(() => import('pages/orders/OrdersForDeliverymanCabinet'))

// Ordering and order Accounting pages for Manufacturers from Our
const OrdersForManufacturer = lazy(() => import('pages/ordersForManufacturer/OrdersForManufacturer'))
const OrderAddForManufacturer = lazy(() => import('pages/ordersForManufacturer/add/OrderAddForManufacturer'))

// Orders Acceptance
const OrdersAcceptance = lazy(() => import('pages/ordersAcceptance/OrdersAcceptance'))
const OrdersAcceptanceAdd = lazy(() => import('pages/ordersAcceptance/add/OrdersAcceptanceAdd'))

// Accounting histories
const Accounting = lazy(() => import('pages/accounting/Accounting'))
const AccountingCurrentOrder = lazy(() => import('pages/accounting/AccountingCurrentOrder'))

// Accounting for Manufacturers histories
const AccountingForManufacturers = lazy(() => import('pages/accountingForManufacturers/AccountingForManufacturers'))
const AccountingForManufacturersCurrentOrder = lazy(() => import('pages/accountingForManufacturers/AccountingForManufacturersCurrentOrder'))

// Payment Methods Pages
const PaymentMethod = lazy(() => import('pages/paymentMethod/PaymentMethod'))
const PaymentMethodAdd = lazy(() => import('pages/paymentMethod/add/PaymentMethodAdd'))

// Warehouse Pages
const Warehouse = lazy(() => import('pages/warehouse/Warehouse'))

// Company About
const Settings = lazy(() => import('pages/settings/Settings'))

// Creator pages
const ProductsPDFCreator = lazy(() => import('pages/productsPDFCreator/ProductsPDFCreator'))
const ProductsMarkerCreator = lazy(() => import('pages/productsMarkerCreator/ProductsMarkerCreator'))
const ProductsBarcodeCreator = lazy(() => import('pages/productsBarcodeCreator/ProductsBarcodeCreator'))

const getChildElement = (path: string, element: ReactNode): IGetChildElement => ({ path, element })
const getRedirect = (path: string = '*'): IGetChildElement => ({ path: path, element: <Navigate to='list' /> })

// Admins routes paths
const ROUTE_PATH_NAMES = {
  ALL: '*',
  HOME: '/',
  NEWS: '/news/*',
  USER: '/user/*',
  ROLES: '/roles/*',
  ORDERS: '/orders',
  USERS: '/users/*',
  COLORS: '/colors/*',
  ADMINS: '/admins/*',
  SETTINGS: '/settings',
  SELF_ADS: '/selfADS/*',
  WAREHOUSE: '/warehouse',
  PRODUCTS: '/products/*',
  MATERIALS: '/materials/*',
  DASHBOARD: '/dashboard/*',
  ACCOUNTING: '/accounting/*',
  CATEGORIES: '/categories/*',
  BI_DASHBOARD: '/bi-dashboard/*',
  PAYMENT_METHODS: '/paymentMethods/*',
  USERS_WAREHOUSE: '/users-warehouse/*',
  ORDERS_ACCEPTANCE: '/orders-acceptance/*',
  USERS_ACCOUNTING: '/users-accounting/list',
  TELEGRAM_BOT_USERS: '/telegram-bot-users/*',
  PRODUCTS_PDF_CREATOR: '/pdf-creator',
  PRODUCTS_MARKER_CREATOR: '/marker-creator',
  PRODUCTS_BARCODE_CREATOR: '/barcode-creator',
  ORDERS_FOR_MANUFACTURERS: '/orders-for-manufacturers',
  PRODUCTS_SOLD_REPORT: '/bi-dashboard/products-sold-report',
  ACCOUNTING_FOR_MANUFACTURERS: '/accounting-for-manufacturers',
  ORDERS_AND_ACCOUNTINGS_DASHBOARD: '/orders-and-accountings-dashboard/*',
}

// Guest router paths
export const guestRoutes: IRoute[] = [
  { path: '/login', element: <Login /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '*', element: <Navigate to='/login' /> },
]

// Default admin routes
export const defaultAdminRoutes: IRoutes[] = [
  { path: ROUTE_PATH_NAMES.DASHBOARD, element: <Dashboard /> },
  { path: ROUTE_PATH_NAMES.ALL, element: <Navigate to='/dashboard' /> },
  { path: ROUTE_PATH_NAMES.HOME, element: <Navigate to='/dashboard' /> },
  { path: ROUTE_PATH_NAMES.ORDERS_AND_ACCOUNTINGS_DASHBOARD, element: <OrdersAndAccountingsDashboard /> },
  { path: ROUTE_PATH_NAMES.USER, children: [getChildElement('profile', <Profile />), getChildElement('settings', <ProfileSettings />), getRedirect('profile')] },

  { path: ROUTE_PATH_NAMES.ORDERS_ACCEPTANCE, children: [getChildElement('list', <OrdersAcceptance />), getChildElement('add', <OrdersAcceptanceAdd />), getRedirect()] },
]

// Admins router
const adminRoutes: IRoutes[] = [
  { path: ROUTE_PATH_NAMES.SETTINGS, element: <Settings /> },
  { path: ROUTE_PATH_NAMES.WAREHOUSE, element: <Warehouse /> },
  { path: ROUTE_PATH_NAMES.PRODUCTS_PDF_CREATOR, element: <ProductsPDFCreator /> },
  { path: ROUTE_PATH_NAMES.PRODUCTS_MARKER_CREATOR, element: <ProductsMarkerCreator /> },
  { path: ROUTE_PATH_NAMES.PRODUCTS_BARCODE_CREATOR, element: <ProductsBarcodeCreator /> },

  { path: ROUTE_PATH_NAMES.USERS_ACCOUNTING, element: <UsersAccounting /> },
  { path: ROUTE_PATH_NAMES.USERS, children: [getChildElement('list', <Users />), getChildElement('add', <UsersAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.ADMINS, children: [getChildElement('list', <Admins />), getChildElement('add', <AdminAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.COLORS, children: [getChildElement('list', <Colors />), getChildElement('add', <ColorAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.SELF_ADS, children: [getChildElement('list', <SelfADS />), getChildElement('add', <SelfADSAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.PRODUCTS, children: [getChildElement('list', <Products />), getChildElement('add', <ProductsAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.ROLES, children: [getChildElement('list', <AdminRoles />), getChildElement('add', <AdminRolesAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.NEWS, children: [getChildElement('list', <CompanyNews />), getChildElement('add', <CompanyNewsAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.MATERIALS, children: [getChildElement('list', <Materials />), getChildElement('add', <MaterialAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.CATEGORIES, children: [getChildElement('list', <Categories />), getChildElement('add', <CategoryAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.PAYMENT_METHODS, children: [getChildElement('list', <PaymentMethod />), getChildElement('add', <PaymentMethodAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.TELEGRAM_BOT_USERS, children: [getChildElement('list', <TelegramBotUsers />), getChildElement('send-message', <SendMessageToTelegramBotUsers />), getRedirect()] },
]

// Generate routes based on authentication status
function generateRoutes(authed: boolean, roles: string[]): IRoutes[] {
  if (!authed) {
    return guestRoutes
  } else {
    const validRoutes: IRoutes[] = []

    roles?.map((role: string) => {
      if (role === 'boss-role' || role === 'all') {
        validRoutes?.push(...adminRoutes)
        validRoutes?.push({
          path: ROUTE_PATH_NAMES.USERS_WAREHOUSE,
          children: [getChildElement('list', <UsersWarehouseProducts />), getChildElement('add', <UsersWarehouseProductAdd />), getRedirect()],
        })
        validRoutes?.push({
          path: ROUTE_PATH_NAMES.ORDERS_FOR_MANUFACTURERS,
          children: [getChildElement('', <OrdersForManufacturer />), getChildElement('add', <OrderAddForManufacturer />), getRedirect()],
        })
        validRoutes?.push({ path: ROUTE_PATH_NAMES.ORDERS, children: [getChildElement('', <Orders />), getChildElement('add', <OrderAdd />), getRedirect()] })
        validRoutes?.push({ path: ROUTE_PATH_NAMES.ACCOUNTING, children: [getChildElement('', <Accounting />), getChildElement('order', <AccountingCurrentOrder />)] })
        validRoutes?.push({
          path: ROUTE_PATH_NAMES.ACCOUNTING_FOR_MANUFACTURERS,
          children: [getChildElement('', <AccountingForManufacturers />), getChildElement('order', <AccountingForManufacturersCurrentOrder />)],
        })

        validRoutes?.push({ path: ROUTE_PATH_NAMES.BI_DASHBOARD, element: <BIDashboard /> })
        validRoutes?.push({ path: ROUTE_PATH_NAMES.PRODUCTS_SOLD_REPORT, element: <ProductsSoldReport /> })
      } else if (role === 'salesman-boss') {
        validRoutes?.push({ path: ROUTE_PATH_NAMES.WAREHOUSE, element: <Warehouse /> })

        validRoutes?.push({ path: ROUTE_PATH_NAMES.ADMINS, children: [getChildElement('list', <Admins />), getChildElement('add', <AdminAdd />), getRedirect()] })
        validRoutes?.push({ path: ROUTE_PATH_NAMES.ORDERS, children: [getChildElement('', <Orders />), getChildElement('add', <OrderAdd />), getRedirect()] })
        validRoutes?.push({ path: ROUTE_PATH_NAMES.ACCOUNTING, children: [getChildElement('', <Accounting />), getChildElement('order', <AccountingCurrentOrder />)] })

        validRoutes?.push({ path: ROUTE_PATH_NAMES.BI_DASHBOARD, element: <BIDashboard /> })
        validRoutes?.push({ path: ROUTE_PATH_NAMES.PRODUCTS_SOLD_REPORT, element: <ProductsSoldReport /> })
      } else if (role === 'salesman') {
        validRoutes?.push({ path: ROUTE_PATH_NAMES.WAREHOUSE, element: <Warehouse /> })

        validRoutes?.push({ path: ROUTE_PATH_NAMES.ORDERS, children: [getChildElement('', <Orders />), getChildElement('add', <OrderAdd />), getRedirect()] })
        validRoutes?.push({ path: ROUTE_PATH_NAMES.ACCOUNTING, children: [getChildElement('', <Accounting />), getChildElement('order', <AccountingCurrentOrder />)] })
      } else if (role === 'warehouser') {
        validRoutes?.push({ path: ROUTE_PATH_NAMES.WAREHOUSE, element: <Warehouse /> })

        validRoutes?.push({ path: ROUTE_PATH_NAMES.ORDERS, element: <OrdersForWarehouserCabinet /> })
        validRoutes?.push({ path: ROUTE_PATH_NAMES.ORDERS_FOR_MANUFACTURERS, element: <OrdersForManufacturer /> })
        validRoutes?.push({ path: ROUTE_PATH_NAMES.ACCOUNTING, children: [getChildElement('', <Accounting />), getChildElement('order', <AccountingCurrentOrder />)] })
        validRoutes?.push({
          path: ROUTE_PATH_NAMES.ACCOUNTING_FOR_MANUFACTURERS,
          children: [getChildElement('', <AccountingForManufacturers />), getChildElement('order', <AccountingForManufacturersCurrentOrder />)],
        })
      } else if (role === 'supplier') {
        validRoutes?.push({ path: ROUTE_PATH_NAMES.WAREHOUSE, element: <Warehouse /> })

        validRoutes?.push({
          path: ROUTE_PATH_NAMES.USERS_WAREHOUSE,
          children: [getChildElement('list', <UsersWarehouseProducts />), getChildElement('add', <UsersWarehouseProductAdd />), getRedirect()],
        })
        validRoutes?.push({
          path: ROUTE_PATH_NAMES.ORDERS_FOR_MANUFACTURERS,
          children: [getChildElement('', <OrdersForManufacturer />), getChildElement('add', <OrderAddForManufacturer />), getRedirect()],
        })
        validRoutes?.push({ path: ROUTE_PATH_NAMES.ACCOUNTING, children: [getChildElement('', <Accounting />), getChildElement('order', <AccountingCurrentOrder />)] })
        validRoutes?.push({
          path: ROUTE_PATH_NAMES.ACCOUNTING_FOR_MANUFACTURERS,
          children: [getChildElement('', <AccountingForManufacturers />), getChildElement('order', <AccountingForManufacturersCurrentOrder />)],
        })

        validRoutes?.push({ path: ROUTE_PATH_NAMES.BI_DASHBOARD, element: <BIDashboard /> })
        validRoutes?.push({ path: ROUTE_PATH_NAMES.PRODUCTS_SOLD_REPORT, element: <ProductsSoldReport /> })
      } else if (role === 'deliveryman') {
        validRoutes?.push({ path: ROUTE_PATH_NAMES.ORDERS, element: <OrdersForDeliverymanCabinet /> })
      } else {
        adminRoutes?.map((adminRoute: IRoutes) => {
          if (role === adminRoute?.path?.split('/')?.join('')?.split('*')?.join('')) {
            validRoutes?.push(adminRoute)
          }
        })
      }
    })

    return [
      {
        path: ROUTE_PATH_NAMES.HOME,
        element: <MainLayout />,
        children: [...defaultAdminRoutes, ...validRoutes],
      },
    ]
  }
}

export default generateRoutes