import axios, { AxiosInstance, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios'

// Redux store
import store from 'store'
import { expireSession } from 'store/store'

// Helpers
import toast from 'react-hot-toast'
import { getUserToken } from 'helpers'

const BASE_URL: string = 'https://api.flakon.uz/api/'
const myAxios: AxiosInstance = axios.create({ baseURL: BASE_URL, timeout: 30000, headers: { 'Content-Type': 'application/json' } })

myAxios?.interceptors?.request?.use(
  (config: InternalAxiosRequestConfig) => {
    const USER_TOKEN: string | null = getUserToken(config?.url || '')
    const requiresToken: boolean = !(config?.url === '/admins/login' || config?.url === '/admins/admin/profile-password' || config?.url?.includes('admins/admin/verifyCodeForResetPassword'))

    if (!USER_TOKEN && requiresToken) {
      store?.dispatch(expireSession())
      return Promise?.reject(new AxiosError('User token required but not found!'))
    }
    if (USER_TOKEN) config.headers['Authorization'] = USER_TOKEN

    return config
  },
  (error) => Promise?.reject(error),
)
myAxios?.interceptors?.response?.use(
  (response: AxiosResponse) => {
    const message = response?.data?.message || ''
    if (message) toast[response?.status > 300 ? 'error' : 'success'](message?.en || message || 'Success!')
    return response
  },
  (error: AxiosError) => {
    const errorMessage: string = (error.response?.data as any)?.message?.en || (error.response?.data as any)?.message || error.message || 'Something went wrong, try again later!'
    toast?.error(errorMessage)
    return Promise?.reject(error)
  },
)

export default myAxios